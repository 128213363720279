import React, { Suspense, lazy } from 'react';
import ReactDOM                  from 'react-dom';
import Spinner                   from "@components/spiners/Spinner";
import reportWebVitals           from '@src/reportWebVitals';


const App = lazy(() => import('@src/App'));

ReactDOM.render(
   <>
      <Suspense fallback={<Spinner/>}>
         <App/>
      </Suspense>
   </>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
